<template>
    <div>
        <MyDocument ref="myDocumentPage" @backChanged="backChanged" @pageChange="pageChange" :content="data" @nochecked="nochecked" :searchComponent='searchComponent' v-if="tabValue == 2"></MyDocument>

        <TableshowDoc ref="myTableDocumentPage" :noCheckedFlag="noCheckedFlag" :tabValue="tabValue" @pageChange="pageChange" :searchComponent='searchComponent' v-else></TableshowDoc>

        <!-- <TableSort ref="tableSortPage"></TableSort> -->
    </div>
</template>
<script>
import MyDocument from '../files/MyDocument.vue' // 我的文档 视图模式
import TableshowDoc from './components/TableShowDoc.vue' // 我的文档 列表模式
// import TableSort from './components/TableSort.vue' // 排序
export default {
    data(){
        return{
            tabValue: 1, // tab切换视图还是列表
            //返回按钮
            back: {
                visable: false
            },
            data:[],
            tableCheckedData: [],
            vals: '全部',
            systemTag: [],
            newObj: {},
            pageNum: 1,
            pageSize: 10,
            textVal: '', // 文字搜索
            noCheckedFlag: false ,
            targetVal:'',
            searchComponent:false
        }
    },
    inject: ["setQuery"],
    components: {
        MyDocument,
        TableshowDoc,
        // TableSort
    },
    watch: {
       
    },
    mounted(){
        // 上传
        this.$ebus.$on('uploadFileBtn',res=>{
            if(this.tabValue == 2) {
                this.$nextTick(()=>{
                    this.$refs.myDocumentPage.handleCommand({type: 'upload'});
                })
            } else if(this.tabValue == 1) {
                this.$nextTick(()=>{
                    this.$refs.myTableDocumentPage.handleCommand({type: 'upload'});
                })
            }
        })
        // 新建
        this.$ebus.$on('addNewFileBtn', res=>{
            if(this.tabValue == 2) {
                this.$nextTick(()=>{
                    this.$refs.myDocumentPage.handleCommand({type: 'mkdir'});
                })
            } else if(this.tabValue == 1) {
                this.$nextTick(()=>{
                    this.$refs.myTableDocumentPage.handleCommand({type: 'mkdir'});
                })
            }
        }),
        // 新建文件
        this.$ebus.$on('createFile', res=>{
            if(this.tabValue == 2) {
                this.$nextTick(()=>{
                    this.$refs.myDocumentPage.showNewCrateFile({type: 'mkFile'});
                })
            } else if(this.tabValue == 1) {
                this.$nextTick(()=>{
                    this.$refs.myTableDocumentPage.showNewCrateFile({type: 'mkFile'});
                })
            }
        }),

        // 搜索
        // this.$ebus.$on('changeRadioEvent', val=>{
            
        //     this.searchEvent(val, this.textVal ,this.targetVal);
        // })
        this.$ebus.$on('changeInputEvent',obj =>{
            this.vals = obj.type;
            this.textVal = obj.title;
            this.targetVal = obj.target;
            this.pageNum = 1;
            this.searchEvent(obj.type, obj.title ,obj.target);
        })
        // tab切换
        this.$ebus.$on('callBackTab', res=>{
            this.tabValue = res;

            if(this.tabValue == 1) { // 让表格的数据选中
                this.$nextTick(()=>{
                    this.$refs.myTableDocumentPage.tabCheckedBoxEvent();
                })
            } else { // 让卡片的数据选中
                if(this.$refs.myTableDocumentPage) {
                    let newMulitp = this.$refs.myTableDocumentPage.multipleSelection;
                    this.$nextTick(()=>{
                        let newFiles = this.$refs.myDocumentPage.viewer.showFiles;
                        newFiles.forEach(items=>{
                            this.$store.commit("cart/remove", {
                                viewerKey: 'share',
                                doc: items,
                            })
                        })

                        newMulitp.forEach(keys=>{
                            newFiles.forEach(row=>{
                                if(row.id == keys.id) {
                                    this.$store.commit("cart/push", {
                                        viewerKey: 'share',
                                        doc: row,
                                    })
                                }
                            })
                        })
                    
                    })
                    this.$nextTick(() => this.$forceUpdate())
                }
            }
        })
    },
    methods: {
        searchEvent(vals, textVal,target){
            this.systemTag = [];
            if(vals != '全部'){
                this.systemTag.push(vals);
            }
            this.searchComponent = textVal != '' && textVal != null ? true : false;
            this.newObj = {
                miji: [],
                systemTag:this.systemTag,
                keyword: textVal,
                doctype:0,
                target:target,
                parentId:this.$store.state.folderBreadList.length>0? this.$store.state.folderBreadList[0].data['id'] : 0
            }
            this.setQuery(this.newObj, {
                pageNum: this.pageNum,
                pageSize: this.pageSize
            });
        },
        //返回按钮状态
        backChanged(state){
            this.back.visable = state
        },
        // 分页回调
        pageChange(val) {
            this.pageNum = val;
            this.searchEvent(this.vals, this.textVal , this.targetVal);
        },
        nochecked(flag) {
            this.noCheckedFlag = flag;
        }
    },
    beforeDestroy() {
        this.$ebus.$off('uploadFileBtn');
        this.$ebus.$off('addNewFileBtn');
        this.$ebus.$off('changeRadioEvent');
        this.$ebus.$off('changeInputEvent');
        this.$ebus.$off('callBackTab');
    }
}
</script>
<style lang="less" scoped>

</style>