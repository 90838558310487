<template>
    <div>
        <!-- <div class="tools-panel">
            <span v-if="viewer.depth > 0" @click="doFolderBack">返回上一级</span>
        </div> -->

        <el-table v-if="!searchComponent && viewer.showFiles.length != 0"
        :data="viewer.showFiles"
        size="small"
        height="calc(100vh - 265px)"
        ref="multipleTable"
        :row-key="row=>row.id"
        :row-class-name="tableRowClassName"
        @cell-mouse-enter="handleCellEnterEvent"
        @cell-mouse-leave="handleCellLeaveEvent"
        @selection-change="handleSelectionChange"
        style="width: 100%;margin-bottom: 20px;">
            <el-table-column
            type="selection"
            width="50"
            :reserve-selection="true"
            align="center">
            </el-table-column>
            <el-table-column
                
                label="文件名"
                width="400">
                <template slot-scope="scope">
                    <div class="file-box" @click="handleRowClickEvent(scope.row)">
                        <div>
                            <img :src="coverImg(scope.row)" v-if='scope.row.type==0' lazy>
                            <img :src="thumbIcon(scope.row)" v-else lazy>
                            <span>{{scope.row.name}}</span>
                            <i class="uploading" v-if='scope.row.state == 1'>上传中</i>
                        </div>
                        <div class="btns" v-show="isShowCellFlag && (rowCellInfo.id == scope.row.id)" @click.stop>
                            <el-button type="text"  v-if='!$isRunInsert' title="文档链" @click.stop="handleCommand({type: 'chain', row: scope.row})" icon="el-icon-link"></el-button>
                            
                            <!-- 购物车 -->
                            <!-- <el-button v-if="!cartChecker(scope.row)" style="margin-left: 20px;" type="text" title="添加待共享列表" @click.stop="handleCommand({type: 'addShare', row: scope.row})"  icon="el-icon-circle-plus-outline"></el-button>
                            <el-button v-else-if="cartChecker(scope.row)" style="color: #f56c6c;margin-left: 20px;" type="text" title="移除待共享列表" @click.stop="handleCommand({type: 'removeShare', row: scope.row})"  icon="el-icon-remove-outline"></el-button> -->
                           
                            <el-dropdown @command="handleCommand">
                                <span class="el-dropdown-link" @click.stop="">
                                    <el-button style="margin-left: 20px;" type="text" title="更多" icon="el-icon-more"></el-button>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item icon="el-icon-edit-outline" :command="{type: 'about', row: scope.row}">文档详情</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-edit" :command="{ type: 'rename', row: scope.row }">重命名</el-dropdown-item>
                                    <el-dropdown-item v-if='scope.row.type == 1' icon="el-icon-download" :command="{ type: 'download', row: scope.row.id }">下载</el-dropdown-item>
                                    <el-dropdown-item v-if='scope.row.type == 1' icon="el-icon-document-copy" :command="{ type: 'copysDocument', row: scope.row.id }">复制文档</el-dropdown-item>
                                    <el-dropdown-item v-if='scope.row.type == 1' icon="el-icon-refresh" :command="{ type: 'moveDocument', row: scope.row.id }">移动文档</el-dropdown-item>
                                    <el-dropdown-item v-if='scope.row.type == 1'  icon="el-icon-collection-tag" :command="{ type: 'bindTag', row: scope.row }">标签管理</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-document-delete" :command="{type: 'delete', row: scope.row}">删除</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-edit" v-if="scope.row.type!=0 && isEditing == '1' && editingSuffix.indexOf(scope.row.suffix) !=-1  && editingType== '0'  && scope.row.suffix !='pdf' " :command="{type: 'wpsEdit', row: scope.row}">在线编辑</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-edit" v-if="scope.row.type!=0 && isEditing == '1' && editingSuffix.indexOf(scope.row.suffix) !=-1  && editingType== '1'  && scope.row.suffix !='pdf'" :command="{type: 'onlyOffice', row: scope.row}">在线编辑</el-dropdown-item>
                                    <!-- <el-dropdown-item icon="el-icon-document" v-if=" scope.row.type!=0" :command="{type: 'tranfromOFD', row: scope.row}">转换OFD文件</el-dropdown-item> -->
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="文件类型"
                align="center">
                <template slot-scope="scope">
                    {{ scope.row.suffix || "文件夹" }}
                </template>
            </el-table-column>
            <el-table-column
                prop="ownerName"
                label="所有者"
                align="center">
            </el-table-column>
             <el-table-column
                prop="sort"
                label="置顶顺序"
                align="center">
                <template slot-scope="scope">
                    {{ scope.row.sort || "无" }}
                </template>
            </el-table-column>
            <el-table-column
                label="操作时间"
                align="center">
                <template slot-scope="scope">
                    {{scope.row.createDate |date}}
                </template>
            </el-table-column>
            <el-table-column
            v-if='!$isRunInsert'
            label="操作"
            width="150"
            align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click.stop="handleCommand({type: 'quStart', row: scope.row})" v-if="scope.row.favoriteCount != 0 && scope.row.type != 0 ">
                        <!-- <i class="el-icon-star-on"></i> -->
                        取消收藏
                    </el-button>
                    <el-button type="text" size="small" @click.stop="handleCommand({type: 'stars', row:  scope.row})" v-if="scope.row.favoriteCount == 0 && scope.row.type != 0 ">
                        <!-- <i class="el-icon-star-off"></i> -->
                        收藏
                    </el-button>
                    <el-button v-if='scope.row.suffix != "mp3" && scope.row.suffix != "mp4" && scope.row.suffix != "zip"' type="text" size="small" @click.stop="handleCommand({type: 'tableshare', row:  scope.row})">
                        <!-- <i class="el-icon-share"></i> -->
                        共享
                    </el-button>
                    <el-button type="text" size="small" v-if='scope.row.sort == null && scope.row.type != 0' @click.stop="handleCommand({type: 'sort', row:  scope.row})">
                        置顶
                    </el-button>
                     <el-button type="text" size="small" v-if='scope.row.sort != null && scope.row.type != 0' @click.stop="handleCommand({type: 'nosort', row:  scope.row})">
                       取消置顶
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="no-data-box" v-if='viewer.showFiles.length == 0'>
            <img src="@/assets/no-file.svg" alt="">
            <span>暂无数据</span>
        </div>


        <!-- 检索组件 -->
        <SearchComponent ref='SearchComponent' v-if='searchComponent' :viewer='viewer'></SearchComponent>

        <div class="pagination" v-if="viewer.showFiles.length > 0">
            <el-pagination
            class="pagebox"
            @current-change="pageChanged"
            :page-size="pageSize"
            layout="total,  prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>

        <!-- 文件名称操作 -->
        <FolderName ref="folderName" :viewerKey="viewerKey" :renameId="renameId" datatype="doc"></FolderName>

        <!-- <ShareDialog ref="shareDialog"></ShareDialog>-->
        <FilePropsDialog ref="filePropsDialog"></FilePropsDialog>
        <FileDownloadDialog ref="fileDownloadDialog"></FileDownloadDialog>
        <FileChainDialog ref="fileChainDialog"></FileChainDialog>
        <!-- <FilePrinter ref="FilePrinter"></FilePrinter> -->
        <UploadFile ref="uploadFile"></UploadFile>

        <AddDocumentDialog ref="addDocumentDialog" :documents="filterFiles" @onItemClicked="onItemClicked" 
        :checkedMap="docIdMap" :newViewer="newViewers" @backAppiot="removeFile" @doFolderBacks="doFolderBacks" :selectFiles="selectFiles"
        :showCheckbox="showCheckbox" @closeEvent="closeEvent"></AddDocumentDialog>

        <BindTag ref="bindTagPage"></BindTag>
        <ChangeFileType ref="changeFileType" @createChangeOver="loadAndRefresh"></ChangeFileType>
     
        <CreateFile ref="createFile"  @createOver='loadAndRefresh'/>
        <OnlyOffice ref='onlyOffice' @createOver='loadAndRefresh'></OnlyOffice>

          <!-- 排序 -->
        <FileSort ref='FileSort' @loadAndRefresh='loadAndRefresh'></FileSort>
    </div>
</template>
<script>
import ChangeFileType from '../ChangeFileType.vue'
import defaultImg from '@/assets/folder.png'
import morenImg from '@/assets/moren.png'

// import TableViewer from "./FilesTableViewer.vue";
// import ContextMenu from "./ContextMenu.vue";
// import ShareManager from "./ShareManager.vue";
import FolderName from "../../files/FolderName.vue";

import FilePropsDialog from "../../files/FileProps.vue";
import FileDownloadDialog from "../../files/Download.vue";
import UploadFile from "@/components/UploadFile.vue"

import FileChainDialog from "../../files/FileChain.vue";
import FilePrinter from "../../files/FilePrinter.vue";
import ShareToMe from "../../files/ShareToMe.vue";
import { number, time } from 'echarts';
import {shareAPI, dollectionAPI,documentAPI ,msgAPI} from 'liankong-ui-api' 
import AddDocumentDialog from '../../files/AddDocumentDialog.vue'
import BindTag from './BindTag.vue'
import CreateFile from './CreateFile.vue'
import OnlyOffice from './OnlyOffice.vue'
import { Store } from '@lk/cache'
import SearchComponent from '../../files/Search.vue'


import iconDefault from '@/assets/img/icon-def.png'
import iconUnknown from '@/assets/img/icon-unknown.png'
import iconImage from '@/assets/img/icon-picture.png'
import iconOFD from '@/assets/img/icon-ofd.png'
import iconAudio from '@/assets/img/icon-audio.png'
import iconWord from '@/assets/img/icon-word.png'
import iconPpt from '@/assets/img/icon-ppt.png'
import iconPdf from '@/assets/img/icon-pdf.png'
import iconExcl from '@/assets/img/icon-excl.png'
import iconMP3 from '@/assets/doc-icon/mp3.png'
import iconMP4 from '@/assets/doc-icon/mp4.png'
import iconZIP from '@/assets/doc-icon/zip.png'
import FileSort from './FileSort.vue';

//后缀映射
const suffixIconCache = {
  def: iconDefault,
  unknown: iconUnknown,

  png: iconImage,
  jpg: iconImage,
  jpeg: iconImage,
  bmp: iconImage,

  ofd: iconOFD,

  doc: iconWord,
  DOC: iconWord,
  docx: iconWord,

  mp3: iconMP3,
  mp4: iconMP4,
  zip: iconZIP,
  ppt:iconPpt,
  pptx:iconPpt,
  pdf:iconPdf,
  xls:iconExcl,
  xlsx:iconExcl
}

export default {
    props: {
        tabValue: {
            default: 1
        },
        noCheckedFlag: {
            default: false
        },
        searchComponent:{
            type:Boolean,
            default:false
        }
    },
    components: {
      // TableViewer,
      // ContextMenu,
      // ShareManager,
      FolderName,
      SearchComponent,
      UploadFile,
      FilePropsDialog,
      FileDownloadDialog,
      FileChainDialog,
      FilePrinter,
      ShareToMe,
      AddDocumentDialog,
      BindTag,
      ChangeFileType,

      CreateFile,
      OnlyOffice,
      FileSort
    },
    data() {
        return {
            form: {
                type: [],
            },
            viewerKey: 'my',
            cartViewKey: "share",
            renameId:'',

            //选择配置
            options: {
                //文件数
                count: Number.MAX_VALUE,
                /**
                 * 筛选类型
                 * 默认包含文件夹和文件
                 * 0：文件夹
                 */
                type: null,

                //回调函数
                callback: "onFileSelected"
                // callback: "myDialogList"
            },

            //选择文件
            selectFiles: [],
            //已选文档id缓存
            docIdMap: {},
            isMove: true,
            docuInfoId: '',
            // 获取个人文档- 弹框 的视图
            newViewerKey: 'fileSelector',
            showCheckbox: false,
            rowCellInfo: {}, // 鼠标滑过的表格信息
            isShowCellFlag: false,
            multipleSelection: [],
            clearFiles: {},
            isEditing:'0',
            editingSuffix:['xlsx','xls','docx','doc','ppt','pptx','wps','pdf','rtf','csv'],
            editingType:null
        };
    },
    inject: ['editRefreshLeftMenu'],
    computed: {
        viewer() {
            return this.$store.state.doc.viewer[this.viewerKey]
        },
        newViewers() {
            return this.$store.state.doc.viewer[this.newViewerKey] // 弹框
        },
        checkedMap(){
            return this.$store.state.cart.views[this.cartViewKey].docIdMap
        },
        pageSize() {
            return 10
            // this.$store.state.doc.viewer[this.viewerKey].query.pageSize || 0
        },
        total() {
            return this.$store.state.doc.viewer[this.viewerKey].paging.total || 0
        },
        //购物车检查，判断是否选中
        cartChecker(){
            return (doc) => {
                const mapKey = doc.id
                return this.checkedMap[mapKey]
            }
        },

        coverImg(){
            return (doc) => doc.type == 1 ?(doc.coverImg!=null? this.$options.filters["thumbnailUrl"](doc.coverImg):morenImg ): defaultImg
        },
        // 文档类型过滤 -- 弹框
        filterFiles(){
            let that = this
            const newShowFiles = this.newViewers ? this.newViewers.showFiles : []
            // console.log(newShowFiles, '文档类型过滤=====')
            newShowFiles.map( items =>{
                that.$set(items,'isChecked',false)
            })
            return this.options.type == null ? newShowFiles : newShowFiles.filter(f => f.type == this.options.type)
        }
    },
    methods: {
        tableRowClassName({row}){
            if(row.state == 1){
                return 'warm-row'
            }
        },
        showNewCrateFile(){
        this.$refs['changeFileType'].init()
      },
        // 图标
        thumbIcon(doc){
            let iconKey = "unknown"
            if(suffixIconCache.hasOwnProperty(doc.suffix)){
                iconKey = doc.suffix
            }
            return suffixIconCache[iconKey] 
        },
        // 行点击
        handleRowClickEvent(row, column, event) {
            if(row.state == 1) return
            if(row.suffix == 'zip'){
                this.$message.error('zip格式不支持阅读！')
                return
            }
            // 非嵌入方式
            if(!this.$isRunInsert){
                // 指定格式用wps或者onlyoffice阅读
                if(this.editingSuffix.indexOf(row.suffix) !=-1  && this.isEditing == '1' ){
                    row.isStream = 1
                }else{
                    row.isStream = 2
                }
                if(row.isStream==1){
                    if(this.editingType== '0'){
                        documentAPI.wpsOpenFile({
                            fileId:row.id
                        }).then(res=>{
                            if(res.code==0){
                                let title = '在线编辑'
                                if(row.suffix == 'pdf'){
                                    title = 'PDF阅读'
                                }
                                if(Store.get("myPreference")){
                                    if(Store.get("myPreference").reader_model == '1'){
                                        const routeData = this.$router.resolve({
                                            path:'/wps',
                                            query:{
                                                urls:res.data
                                            }
                                        })
                                        window.open(routeData.href,'_blank');
                                    }else{
                                        this.$refs['createFile'].init(res.data,title)
                                    }
                                }else{
                                    this.$refs['createFile'].init(res.data,title)
                                }
                            
                            } else {
                                this.$message.error(res.message)
                            }
                        })
                    }else{
                        documentAPI.getOnlyOfficeParameter(row.id).then(res=>{
                            if(res.code==0){
                                if(Store.get("myPreference")){
                                    if(Store.get("myPreference").reader_model == '1'){
                                        const routeData = this.$router.resolve({
                                            path:'/onlyOffice',
                                            query:res.data
                                        })
                                        window.open(routeData.href,'_blank');
                                    }else{
                                        this.$refs['onlyOffice'].init(res.data)
                                    }
                                }else{
                                    this.$refs['onlyOffice'].init(res.data)
                                }
                            } else {
                                this.$message.error(res.message)
                            }
                        })
                    }
                    
                } else {
                this.handleCommand({type: 'click', row: row});
                }
            } else {
                // 嵌入方式
                const officeType = ['doc','docx','DOC','DOCX','xls','xlsx','XLS','XLSX','ppt','pptx','PPT','PPTX','pdf','PDF']
                const ofdType = ['ofd','OFD']
                if(officeType.indexOf(row.suffix)!=-1){
                    documentAPI.getOnlyOfficeParameter(row.id).then(res=>{
                        if(res.code==0){
                            const routeData = this.$router.resolve({
                                path:'/onlyOffice',
                                query:res.data
                            })
                        window.open(routeData.href,'_blank');   
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                } else if(ofdType.indexOf(row.suffix)!=-1){
                    window.open('/tools/reader/reader.html?id='+row.id,'_blank')
                } else {
                    if(row.type==0){
                        this.handleCommand({type: 'click', row: row});
                        return
                    }
                    this.$message.warning('暂不支持阅读,正在研发中,敬请期待...')
                }
            }
           
        },
        // 鼠标划入
        handleCellEnterEvent(row, column, cell, event) {
            this.isShowCellFlag = true;
            this.rowCellInfo = row;
        },
        // 鼠标滑出
        handleCellLeaveEvent(row, column, cell, event) {
            this.isShowCellFlag = false;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            if(val.length > 0){
                this.viewer.showFiles.forEach(items=>{
                    this.$store.commit("cart/remove", {
                        viewerKey: this.cartViewKey,
                        doc: items,
                    })
                })
                let documentIdList=[]
                this.multipleSelection.forEach(multipItem=>{
                    documentIdList.push(multipItem.id)
                    this.$store.commit("cart/push", {
                        viewerKey: this.cartViewKey,
                        doc: multipItem,
                    })
                })
            }
        },
        // 共享
        showDialogEvent() {
            if(this.multipleSelection.length > 0) {
                this.viewer.showFiles.forEach(items=>{
                    this.$store.commit("cart/remove", {
                        viewerKey: this.cartViewKey,
                        doc: items,
                    })
                })
                let documentIdList=[]
                this.multipleSelection.forEach(multipItem=>{
                    documentIdList.push(multipItem.id)
                    this.$store.commit("cart/push", {
                        viewerKey: this.cartViewKey,
                        doc: multipItem,
                    })
                })
                this.$store.commit('setCartShow', true)
                this.$ebus.$emit('showMaximize',false)
            } else {
                this.$message.error('请选择数据')
            }
        },
        // 取消选择
        toggleSelection(rows) {
            let that = this;
            if (rows) {
                this.viewer.showFiles.forEach(row => {
                    if(row.id == rows.id) {
                        if(that.$refs.multipleTable) {
                            that.$refs.multipleTable.toggleRowSelection(row, false);
                        }
                    }
                });
            } else {
                that.$refs.multipleTable.clearSelection();
            }
        },
        // 切换过来选中效果
        tabCheckedBoxEvent(){
            let that = this;
            if(this.noCheckedFlag) {
                return
            }
            this.viewer.showFiles.forEach(row=>{
                if(this.checkedMap[row.id] != undefined) {
                    if(this.checkedMap[row.id].id == row.id) {
                        that.$refs.multipleTable.toggleRowSelection(row, true);
                    }
                }
            })
        },
        // 分页
        pageChanged(num) {
           this.$emit('pageChange', num);
           this.toggleSelection(this.clearFiles);
        },

        //加载或刷新当前文件夹
        loadAndRefresh() {
            this.$store.dispatch("doc/loadChildren", {
                vkey: this.viewerKey,
                reset: true,
                pageSize:10
            });
        },
      
        //后退
        doFolderBack(){
            this.$store.dispatch("doc/backFolder", {
                vkey: this.viewerKey,
            })
            this.loadAndRefresh()
            if(this.viewer.depth == 0) {
                this.$store.commit('pageBackTextShow', '')
            }

            this.$store.commit('backFolerBread')
        },

        //文档集中处理器
        handleCommand({type, row}) {
            switch (type) {
                case "click":
                    if (row.type == 0) {
                        //打开文件夹
                        this.handleEnterFolder(row);
                    } else {
                        if(row.documentId !=null){
                            row.id =row.documentId
                        }

                        this.$ebus.$emit('publicReader',{
                            shareId:row.shareId, 
                            id:row.documentId || row.id,
                            documentId: row.documentId,
                            name:row.name,
                            suffix:row.suffix
                        })

                    }
                break;
                case "rename":
                    this.renameId = row.id;
                    this.$refs["folderName"].show({ mode: "rename",value:row.name});
                    break;
                case "reload":
                    this.$store.dispatch("document/loadChildren");
                    break;
                //添加共享购物车
                case "addShare":
                    this.$store.commit("cart/push", {
                        viewerKey: this.cartViewKey,
                        doc: row,
                    })
                    this.$nextTick(() => this.$forceUpdate())
                    break
                //移除共享购物车
                case "removeShare":
                    this.$store.commit("cart/remove", {
                        viewerKey: this.cartViewKey,
                        doc: row,
                    })
                    this.$nextTick(() => this.$forceUpdate())
                    break
                case "upload":
                    this.$ebus.$emit('clearSelect')
                    this.$refs["uploadFile"].show();
                    break;
                case "share":
                    //多选文件处理
                    const shareFiles = [row];

                    if (this.$store.state.document.viewer.selection) {
                        this.$store.state.document.viewer.selectionRows.map((item) => {
                            if (item.id != row.id) {
                                shareFiles.push(item);
                            }
                        });
                    }

                    this.$refs["shareDialog"].show({
                        mode: "new",
                        value: shareFiles,
                    });
                    break;
                case "mkdir":
                    this.$ebus.$emit('clearSelect')
                    this.$refs["folderName"].show({ mode: "new", value: null });
                    break;
                case "about":
                    this.$refs["filePropsDialog"].show(row);
                    break;
                case "download":
                    this.$refs["fileDownloadDialog"].show(row);
                    break;
                case "delete":
                    this.loadingShow("删除中...")
                    this.$store.dispatch('doc/checkDocumentHasShare',[row.id]).then((res)=>{
                    let {data:hasData,message:hasMessage,code:hasCode} = res
                    if(hasCode==0){
                        if(hasData.length>0){
                            this.$confirm('删除文件中存在共享文件, 是否继续删除?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                            }).then(() => {
                               this.removeFewMyFile(row)
                            }).catch(() => { 
                                this.loadingHide()    
                            });
                        } else {
                               this.removeFewMyFile(row)
                        }
                    } else {
                        this.$message.error(hasMessage)
                    }
                })
                    break;
                case "selection":
                    this.$store.commit("doc/setSelection", {
                        value: true,
                        item: row,
                    });
                    break;
                case "chain":
                    this.$refs["fileChainDialog"].show('doc', row);
                    break;
                case "printer":
                    this.$refs["FilePrinter"].show(row);
                    break;

                case "moveDocument": // 移动文档
                    this.isMove = false;
                    this.options.type = 0;
                    this.docuInfoId = row;
                    this.$refs["addDocumentDialog"].show(this.viewerKey);
                    break;

                case "copysDocument": // 复制文档
                    this.isMove = true;
                    this.options.type = 0;
                    this.docuInfoId = row;
                    this.$refs["addDocumentDialog"].show(this.viewerKey);
                    break;

                case "stars":
                    this.addFavorite(row);
                    break;

                case "quStart":
                    this.quStarData(row);
                break;

                case "bindTag":
                    this.bindTagEvent(row);
                break;

                case 'sort':
                    this.$refs.FileSort.show(row)
                break;
                case 'nosort':
                     documentAPI.cancelMyDocumentFileSort(row.id).then(res => {
                        if(res.code == 0){
                            this.$message.success('操作成功！');
                            this.loadAndRefresh()
                        }else{
                            this.$message.error(res.message)
                        }
                    })
                break;

                case "tableshare":
                   this.multipleSelection.forEach(items=>{
                        this.$store.commit("cart/remove", {
                            viewerKey: this.cartViewKey,
                            doc: items,
                        })
                    })
                    this.$refs.multipleTable.clearSelection();
                    this.multipleSelection = []

                    this.$store.commit("cart/push", {
                        viewerKey: this.cartViewKey,
                        doc: row,
                    })
                    let documentIdList = [row.id]
                    this.$ebus.$emit('showMaximize')
                    this.$store.commit('setCartShow', true)
                break;

                case "tranfromOFD": 
                this.loadingShow("转换中...")
                  this.$store.dispatch('doc/transformToOFD',row.id).then(res=>{
                    this.loadingHide()
                    if(res.code==0){
                        this.$message.success('转换成功')
                    } else {
                        this.$message.error('转换失败')
                    }
                  })
                break;
                case 'wpsEdit': 
                    documentAPI.wpsOpenFile({
                            fileId:row.id
                        }).then(res=>{
                            if(res.code==0){
                                if(Store.get("myPreference")){
                                    if(Store.get("myPreference").reader_model == '1'){
                                        const routeData = this.$router.resolve({
                                            path:'/wps',
                                            query:{
                                                urls:res.data
                                            }
                                        })
                                        window.open(routeData.href,'_blank');
                                    }else{
                                        this.$refs['createFile'].init(res.data,'文件编辑')
                                    }
                                }else{
                                    this.$refs['createFile'].init(res.data,'文件编辑')
                                }
                                
                            } else {
                                this.$message.error(res.message)
                            }
                        })
                  break;
                case 'onlyOffice': 
                     documentAPI.getOnlyOfficeParameter(row.id).then(res=>{
                        if(res.code==0){
                            if(Store.get("myPreference")){
                                if(Store.get("myPreference").reader_model == '1'){
                                    const routeData = this.$router.resolve({
                                        path:'/onlyOffice',
                                        query:res.data
                                    })
                                    window.open(routeData.href,'_blank');
                                }else{
                                    this.$refs['onlyOffice'].init(res.data)
                                }
                            }else{
                                this.$refs['onlyOffice'].init(res.data)
                            }
                            
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                   
                break;
            }
        },

        //进入指定目录
        handleEnterFolder(folder) {
            // 文件夹的名称存起来
            this.$store.commit('folderBreadList', {doc: folder, tip: '我的文档'});
            if (folder) {
                if (folder.type == 0) {
                    this.$store.dispatch("doc/enterFolder", {
                        vkey: this.viewerKey,
                        folder,
                        pageSize:10,
                        pageNum:1
                    });
                }
            } else {
                this.$store.dispatch("doc/enterFolder");
            }

            if(this.viewer.depth > 0) {
                this.$store.commit('pageBackTextShow', '我的文档')
            }
        },

        createfilder() {
            this.$prompt("请输入文件名", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            })
            .then(({ value }) => {
                this.$message({
                    type: "success",
                    message: "你的邮箱是: " + value,
                });
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "取消输入",
                });
            });
        },

        prev() {
            this.$store.dispatch("doc/doLoadPrevPage", {
                vkey: this.viewerKey,
            });
        },
        next() {
            this.$store.dispatch("doc/doLoadNextPage", {
                vkey: this.viewerKey,
            });
        },
        //列表项点击
        onItemClicked(key, docInfo, state) {
            this.showCheckbox = true
            switch (key) {
                case "click":
                    //列表项点击
                    if (docInfo.type == 1) {
                        //文件单次点击勾选，再次点击取消
                        const states = !this.docIdMap.hasOwnProperty(docInfo.id)
                        this.onFileChecked(docInfo, states)
                    
                    } else {
                        //进入文件夹
                        this.$store.dispatch("doc/enterFolder", {
                            vkey: this.newViewerKey,
                            folder: docInfo,
                        })
                    }
                    break;
                
                case "checked":
                    this.onFileChecked(docInfo, state)
                    break
            }
        },
        //勾选文件，单次点击勾选，再次点击取消
        onFileChecked(doc){
            this.addFile(doc)
        },

        //勾选文件
        addFile(doc){
            const cacheKey = doc.id

             if (!this.docIdMap[cacheKey]) {
                this.docIdMap[cacheKey] = doc
                this.selectFiles.push(doc)
            }
        },

        //移除文件
        removeFile(data){
            let {item:doc,index} = data
            const cacheKey = doc.id
           
            //判断是否已添加
            if (this.docIdMap[cacheKey]) {
                if(this.selectFiles.length>1&&index!=0){
                    for (let itemIndex = 0, len = this.selectFiles.length; itemIndex < len; itemIndex++) {
                        if (this.selectFiles[itemIndex].id == cacheKey) {
                            this.selectFiles.splice(itemIndex)
                            this.$store.dispatch("doc/enterFolder", {
                                vkey: this.newViewerKey,
                                folder:this.selectFiles[this.selectFiles.length-1]
                            })
                            this.$store.state.doc.viewer[this.newViewerKey].depth = this.selectFiles.length
                            this.$store.state.doc.viewer[this.newViewerKey].paths.forEach((item,index)=>{
                                if(item.id==cacheKey){
                                    this.$store.state.doc.viewer[this.newViewerKey].paths.splice(index)
                                }
                            })
                            delete this.docIdMap[cacheKey]
                            break
                        }
                    }
                } else {
                    this.selectFiles.forEach(item=>{
                        delete this.docIdMap[item.id]
                    })
                    this.$store.state.doc.viewer[this.newViewerKey].current = 0;
                     this.$store.dispatch("doc/loadChildren", {
                        vkey: this.newViewerKey,
                        reset: true,
                        pageSize:10
                    })
                    this.selectFiles = []
                    this.$store.state.doc.viewer[this.newViewerKey].depth = 0
                    this.$store.state.doc.viewer[this.newViewerKey].paths = [];
                }
            }
        },
        docRemoveFile(doc){
            const cacheKey = doc.id
               this.$refs.multipleTable.toggleRowSelection(doc, false);
               for (let itemIndex = 0, len = this.multipleSelection.length; itemIndex < len; itemIndex++) {
                if (this.multipleSelection[itemIndex].id == cacheKey) {
                    this.multipleSelection.splice(itemIndex, 1)
                    break
                }
            }
        },
        //文件目录返回
        doFolderBacks() {
            let lastCacheKey = this.selectFiles[this.selectFiles.length-1] ? this.selectFiles[this.selectFiles.length-1].id : 0
            if (this.docIdMap[lastCacheKey]) {
                this.selectFiles.splice(this.selectFiles.length-1)
                delete this.docIdMap[lastCacheKey]
            }
            this.$store.dispatch("doc/backFolder", {
                vkey: this.newViewerKey
            })
        },
        /**
         * 页面加载
         * options: {
         *    count: 选择文件数，默认不限制
         *    type: null, 选择类型，默认包含文件夹和文件， 0=文件夹
         *    callback: 回调函数，默认为 onFileSelected
         * }
         */ 
        onLoad(){
            //数据类型处理
            this.options.count = 1
            if(this.options.count) this.options.count = Number(this.options.count)

            this.options = Object.assign(this.options, this.options)
            
            this.$store.commit("doc/createViewer", {
                viewerKey: this.newViewerKey
            })
            this.$store.dispatch("doc/loadChildren", {
                vkey: this.newViewerKey,
                reset: true,
                fileType:'0'
            })
        },
        // 清空事件
        closeEvent(){
            this.onLoad()

            this.selectFiles.forEach(a=>{
                this.$set(a,'isChecked',false)
                this.docIdMap[a.id].isChecked = false
            })
            this.docIdMap = {}
            this.selectFiles = []
            this.showCheckbox = false    

        },
        // 添加收藏
        addFavorite(docInfo) {
            this.$store.dispatch("collection/addDollection", docInfo.id)
                .then((data) => {
                    this.$message.success("收藏成功");
                    this.loadAndRefresh();
                }).catch((err) => {
                    this.$msg.error("收藏发生错请稍后再试");
                });
        },
        // 取消收藏
        quStarData(item) {
            dollectionAPI.removeRecoredId(item.id).then(res=>{
                if(res.code == 0) {
                    this.$message.success("取消收藏成功");
                    this.loadAndRefresh();
                } else {
                    this.$message.error(res.message); 
                }
            }).catch(error=>{
                this.$message.error(error);
            })
        },

        bindTagEvent(row) {
            this.$refs.bindTagPage.disableHidden = true;
            this.$nextTick(()=>{
                this.$refs.bindTagPage.init(row);
            })
        },
        // 多删除
        handleMyDocRemoveClickEvent(){            
            if(this.multipleSelection.length > 0){
                let ids = []
                let that = this
                this.multipleSelection.map((mapItems)=>{
                    ids.push(mapItems.id)
                     this.$store.commit("cart/remove", {
                            viewerKey: this.cartViewKey,
                            doc: mapItems,
                        })
                    that.$refs.multipleTable.toggleRowSelection(mapItems, false);
                })
                this.multipleSelection=[]
                this.$store.dispatch('doc/checkDocumentHasShare',ids).then((res)=>{
                    let {data:hasData,message:hasMessage,code:hasCode} = res
                    if(hasCode==0){
                        if(hasData.length>0){
                            this.$confirm('删除文件中存在共享文件, 是否继续删除?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                            }).then(() => {
                               this.removeMoreMyFile(ids)
                            }).catch(() => {         
                            });
                        } else {
                               this.removeMoreMyFile(ids)
                        }
                    } else {
                        this.$message.error(hasMessage)
                    }
                })
               

            } else {
                this.$message.error('请选择数据')
            }
        },
        removeMoreMyFile(ids){
            this.$store.dispatch("doc/checkedRemove", {
                vkey: this.viewerKey,
                id: ids
            }).then((data) => {
                // 文件夹类型的时候 删除刷新左侧菜单
                if(data) {
                    this.loadAndRefresh()
                    this.$message.success('删除成功')
                    this.editRefreshLeftMenu();
                    this.$store.dispatch('login/getUserUsedDist')
                } else {
                    this.$message.error('删除失败')
                    // this.$message.error("当前文件中有未关闭的共享，请先关闭共享")
                }
            }).catch(err => this.$message.error(err))
        },
        removeFewMyFile(row){
            this.$store.dispatch("doc/checkedRemove", {
                vkey: this.viewerKey,
                id: [row.id]
            }).then((data) => {
                this.loadingHide()
                // 文件夹类型的时候 删除刷新左侧菜单
                if(data){
                    this.$message.success('删除成功')
                    if(row.type == 0) {
                        this.editRefreshLeftMenu();
                    }
                    this.$store.commit("cart/remove", {
                        viewerKey: this.cartViewKey,
                        doc: row,
                    })
                    this.docRemoveFile(row)
                    this.loadAndRefresh()
                    this.$store.dispatch('login/getUserUsedDist')
                    this.$nextTick(() => this.$forceUpdate())
                } else {
                    this.$message.error('删除失败')
                }
            }).catch(err => this.$message.error(err))
        },
        // 是否开启了在线编辑功能
        getValueByKey(){
            msgAPI.getValueByKey('system.edit.enable').then(res => {
                if(res.code == 0){
                    this.isEditing  = res.data.configValue;
                    // 如果已经开启了在线编辑功能，那么检测是用哪种方式
                    if(this.isEditing == '1'){
                        msgAPI.getValueByKey('system.edit.type').then(res => {
                            this.editingType = res.data.configValue;
                        })
                    }
                }
            })
        }

    },
    created() {
        this.onLoad();
        this.getValueByKey()
        
        this.$store.state.doc.viewer['my'].current = null;
        this.viewer.depth = 0;
        this.$store.state.doc.viewer[this.viewerKey].depth = 0;
        this.$store.state.doc.viewer[this.viewerKey].paths = [];
        this.$store.dispatch("doc/loadChildren", {
            vkey: this.viewerKey,
            reset: true,
            pageSize:10,
            pageNum:1
        });
        // this.doFolderBack()

        this.$ebus.$on('cart:remove', (file) => {
            this.$nextTick(() => this.$forceUpdate())
        })
    },
    mounted() {
        this.$ebus.$on('clear',res=>{
            this.clearFiles = res;
            this.$nextTick(() => this.$forceUpdate())

            this.toggleSelection(res)
        })

        this.$ebus.$on('myDocGoBackBtn', text=>{
            this.doFolderBack()
        })
        // 跳转到
        this.$ebus.$on('enterMyDocData', vals=>{
            if(vals == '我的文档') {
                this.$store.state.doc.viewer[this.viewerKey].current = null;
                this.loadAndRefresh()
                this.viewer.depth = 0;
                this.$store.state.doc.viewer[this.viewerKey].depth = 0;
                this.$store.state.doc.viewer[this.viewerKey].paths = [];
                this.$store.commit('pageBackTextShow', '')
                this.$store.commit('clearFolderBreadList')
            } else {
                if (vals.data.type == 0) {
                    this.$store.dispatch("doc/enterFolder", {
                        vkey: this.viewerKey,
                        folder: vals.data
                    });
                }
                if(vals.data.children.length > 0) {
                    this.$store.commit('folderBreadDeleteNum', {id: vals.path, vkey: 'my'})
                }
            }
        })
        // 共享
        this.$ebus.$on('handleMyShareEvent', val=>{
            this.showDialogEvent()
            
        })
        
        this.$ebus.$on('handleMyDocRemoveClickEvent', val=>{
            this.handleMyDocRemoveClickEvent();
        })
    },
    beforeDestroy() {
        this.$ebus.$off('myDocGoBackBtn');
        this.$ebus.$off('enterMyDocData');
        this.$ebus.$off('handleMyShareEvent');
        this.$ebus.$off('handleMyDocRemoveClickEvent');
    }
  };
</script>
<style lang="less" scoped>
.tools-panel{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    color: #666666;
    cursor: pointer;
}
/deep/ .el-table {
    .warm-row{
        background: #ccc!important;
       
        opacity: 0.6;
        td{
            pointer-events: none;
            cursor: not-allowed;
        }
        td:nth-child(1){
            pointer-events: initial;
            cursor: inherit;
        }
    }
    .uploading{
        background: #E6A23C;
        display: inline-block;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
        color: #fff;
        padding: 0 10px;
    }
    .file-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        cursor: pointer;
        div:nth-child(1) {
            display: flex;
            img {
                width: 25px;
                height: 25px;
                margin-right: 5px;
            }
            span {
                display: block;
                width: 240px;
                height: 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                // display: -webkit-box;
                // -webkit-line-clamp: 1;
                white-space: nowrap;
                // -webkit-box-orient: vertical;
            }
        }
        .btns {
            position: absolute;
            right: -30px;
            width: 100px;
            margin: 0;
        }
    }
    .el-table__header-wrapper {
        .has-gutter {
            tr th{
                border:none;
                background: #F2F2F2;
                color: #666;
            }
        }
    }
    .el-table__body-wrapper {
        tr td {
            border:none;
            padding: 12px 0;
        }
        .el-table__expand-icon {
            display: none;
        }
    }
    &::before {
        height: 0px;
    }
}
.pagebox {
    margin-top: 20px;
    text-align: left;
}
</style>