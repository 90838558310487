<template>
  <div>
    <el-row :gutter="20" v-if="viewer.showFiles.length > 0 && !searchComponent">
      <el-col :span="5" v-for="doc in viewer.showFiles" :key="doc.id">   
        <el-card :class="doc.state == 1 ? 'warm-row document':'document'" :body-style="{ padding: '0px' }" @click.native="handleRowClickEvent(doc)">
          <div class="document-top">
            <!-- 图片展示 -->
            <img :src="coverImg(doc)"  v-if='doc.type==0'    style="width:80px;height:70px;margin-top:40px"  alt="doc.name" lazy>
            <img :src="coverImg(doc)" v-else style="max-height: 100%;width:100%"  alt="doc.name" lazy>
              <i class="uploading" v-if='doc.state == 1'>上传中</i>
            <!-- <el-image style="height: 100%;" :src="coverImg(doc)" fit="contain" :alt="doc.name" lazy></el-image> -->
          </div>
          <div class="document-middle">
            <el-tooltip effect="light" :content="doc.name" placement="bottom-end">
              <div class="title">
                {{ doc.name }}
              </div>
            </el-tooltip>

            <div class="info-tag">
              <i class="el-icon-time"></i>{{ doc.createDate | date("yyyy-MM-dd") }}
            </div>
            <div class="info-tag">
              <i class="el-icon-wind-power"></i>{{ doc.suffix || "文件夹" }}
            </div>
            <div class="info-tag" v-if="doc.mj">
              <i class="el-icon-delete"></i>保密
            </div>
            <!-- <div class="red_share">
              <span>阅读数:5次</span>
              <span>共享数: 2次</span>
            </div> -->
          </div>
          <div class="document-bottom">
            <el-button class="btn" v-if='!$isRunInsert' type="text" title="文档链" @click.stop="handleCommand({type: 'chain', row: doc})" size="mini" icon="el-icon-link"></el-button>
            
            <!-- 购物车 -->
            <!-- <el-button v-if="!cartChecker(doc)" class="btn" type="text" title="添加待共享列表" @click.stop="handleCommand({type: 'addShare', row: doc})"  icon="el-icon-circle-plus-outline" size="mini"></el-button>
            <el-button v-else class="btn" style="color: #f56c6c;" type="text" title="移除待共享列表" @click.stop="handleCommand({type: 'removeShare', row: doc})"  icon="el-icon-remove-outline" size="mini"></el-button> -->
            <span v-if="!cartChecker(doc)" title="多选" class="checkbox-span" @click.stop="handleCommand({type: 'addShare', row: doc})"></span>
            <span v-else class="checkbox-span1" title="多选" @click.stop="handleCommand({type: 'removeShare', row: doc})"><i class="el-icon-check"></i></span>

            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link" @click.stop="">
                <el-button class="btn" type="text" title="更多" icon="el-icon-more" size="mini"></el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-edit-outline" :command="{type: 'about', row: doc}">文档详情</el-dropdown-item>
                <el-dropdown-item icon="el-icon-edit" :command="{ type: 'rename', row:doc }">重命名</el-dropdown-item>
                <el-dropdown-item v-if='doc.type == 1' icon="el-icon-download" :command="{ type: 'download', row: doc.id }">下载</el-dropdown-item>
                <el-dropdown-item v-if='doc.type == 1' icon="el-icon-document-copy" :command="{ type: 'copysDocument', row: doc.id }">复制文档</el-dropdown-item>
                <el-dropdown-item v-if='doc.type == 1' icon="el-icon-refresh" :command="{ type: 'moveDocument', row: doc.id }">移动文档</el-dropdown-item>
                <el-dropdown-item v-if='doc.type == 1' icon="el-icon-collection-tag" :command="{ type: 'bindTag', row: doc }">标签管理</el-dropdown-item>
                <el-dropdown-item icon="el-icon-document-delete" :command="{type: 'delete', row: doc}">删除</el-dropdown-item>
                <el-dropdown-item icon="el-icon-star-on" v-if="doc.favoriteCount != 0 && doc.type != 0" :command="{type: 'quStart', row: doc}">取消收藏</el-dropdown-item>
                <el-dropdown-item icon="el-icon-star-off" v-if="doc.favoriteCount == 0 && doc.type != 0" :command="{type: 'stars', row: doc}">收藏</el-dropdown-item>
                <el-dropdown-item icon="el-icon-sort-up" v-if="doc.sort == null && doc.type != 0" :command="{type: 'sort', row: doc}">置顶</el-dropdown-item>
                <el-dropdown-item icon="el-icon-sort-down" v-if="doc.sort != null && doc.type != 0" :command="{type: 'nosort', row: doc}">取消置顶</el-dropdown-item>
                <el-dropdown-item icon="el-icon-share" v-if='doc.suffix != "mp3" && doc.suffix != "mp4" && doc.suffix != "zip"'  :command="{type: 'tableshare', row: doc}">共享</el-dropdown-item>
                <el-dropdown-item icon="el-icon-edit" v-if="doc.type!=0 && isEditing == '1' && editingSuffix.indexOf(doc.suffix) !=-1  && editingType== '0' && doc.suffix !='pdf'" :command="{type: 'wpsEdit', row: doc}">在线编辑</el-dropdown-item>
                <el-dropdown-item icon="el-icon-edit" v-if="doc.type!=0 && isEditing == '1' && editingSuffix.indexOf(doc.suffix) !=-1  && editingType== '1'  && doc.suffix !='pdf'" :command="{type: 'onlyOffice', row: doc}">在线编辑</el-dropdown-item>
                <el-dropdown-item icon="el-icon-edit" v-if="doc.type!=0" :command="{type: 'teamwork', row: doc}">协同</el-dropdown-item>
                <!-- <el-dropdown-item icon="el-icon-document" v-if="doc.type!=0" :command="{type: 'tranfromOFD', row: doc}">转换OFD文件</el-dropdown-item> -->
            
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div class="no-data-box"  v-if="viewer.showFiles.length == 0 && !searchComponent">
        <img src="@/assets/no-file.svg" alt="">
        <span>暂无数据</span>
    </div>


      <!-- 检索组件 -->
      <SearchComponent ref='SearchComponent' v-if='searchComponent' :viewer='viewer'></SearchComponent>

    <!-- 分页 -->
    <div class="pagination" v-if="viewer.showFiles.length > 0">
      <el-pagination
      class="pagebox"
      @current-change="pageChanged"
      :page-size="pageSize"
      layout="total,  prev, pager, next, jumper"
      :total="total">
      </el-pagination>
    </div>
    <!-- <el-pagination background layout="prev, pager, next" :page-size="20" :total="viewer.paging.total"
        @prev-click="prev" @next-click="next"></el-pagination> -->

    <!-- 文件名称操作 -->
    <FolderName ref="folderName" :viewerKey="viewerKey" :renameId="renameId" datatype="doc"></FolderName>

    <!-- <ShareDialog ref="shareDialog"></ShareDialog>-->
    <FilePropsDialog ref="filePropsDialog"></FilePropsDialog>
    <FileDownloadDialog ref="fileDownloadDialog"></FileDownloadDialog>
    <FileChainDialog ref="fileChainDialog"></FileChainDialog>
    <!-- <FilePrinter ref="FilePrinter"></FilePrinter> -->
    <UploadFile ref="uploadFile"></UploadFile>

     <AddDocumentDialog ref="addDocumentDialog" :documents="filterFiles" @onItemClicked="onItemClicked" 
    :checkedMap="docIdMap" :newViewer="newViewers" @backAppiot="removeFile" @doFolderBacks="doFolderBacks" :selectFiles="selectFiles"
    :showCheckbox="showCheckbox" @closeEvent="closeEvent"></AddDocumentDialog>

    <BindTag ref="bindTagPage"></BindTag>
    <ChangeFileType ref="changeFileType" @createChangeOver="loadAndRefresh"></ChangeFileType>
   
    <CreateFile ref="createFile"  @createOver='loadAndRefresh' />
    <OnlyOffice ref='onlyOffice'  @createOver='loadAndRefresh'></OnlyOffice>
    <FileSort ref='FileSort'  @loadAndRefresh='loadAndRefresh'></FileSort>
  </div>
</template>
<script>
  import defaultImg from '@/assets/folder.png'
  import morenImg from '@/assets/moren.png'

  // import TableViewer from "./FilesTableViewer.vue";
  // import ContextMenu from "./ContextMenu.vue";
  // import ShareManager from "./ShareManager.vue";
  import FolderName from "./FolderName.vue";

  import FilePropsDialog from "./FileProps.vue";
  import FileDownloadDialog from "./Download.vue";
  import UploadFile from "@/components/UploadFile.vue"
  import FileChainDialog from "./FileChain.vue";
  import FilePrinter from "./FilePrinter.vue";
  import MyDocument from "./MyDocument.vue";
  import ShareToMe from "./ShareToMe.vue";
import {shareAPI, dollectionAPI,documentAPI ,msgAPI} from 'liankong-ui-api' 
import { Store } from '@lk/cache'
import AddDocumentDialog from './AddDocumentDialog.vue'
import BindTag from '../liankong/components/BindTag.vue'
import ChangeFileType from '../liankong/ChangeFileType.vue'
import CreateFile from '../liankong/components/CreateFile.vue'
import OnlyOffice from '../liankong/components/OnlyOffice.vue'
import SearchComponent from './Search.vue'
import FileSort from '../liankong/components/FileSort.vue';
  export default {
    components: {
      // TableViewer,
      // ContextMenu,
      // ShareManager,
      FolderName,
      SearchComponent,

      UploadFile,
      FilePropsDialog,
      FileDownloadDialog,
      FileChainDialog,
      FilePrinter,
      MyDocument,
      ShareToMe,
      AddDocumentDialog,
      BindTag,
      ChangeFileType,

      CreateFile,
      OnlyOffice,
      FileSort
    },
    data() {
      return {
        form: {
          type: [],
        },
        viewerKey: 'my',
        cartViewKey: "share",
        renameId:'',

        //选择配置
        options: {
          //文件数
        count: Number.MAX_VALUE,
          /**
           * 筛选类型
           * 默认包含文件夹和文件
           * 0：文件夹
           */
          type: null,

          //回调函数
          callback: "onFileSelected"
          // callback: "myDialogList"
        },

        //选择文件
        selectFiles: [],
        //已选文档id缓存
        docIdMap: {},
        isMove: true,
        docuInfoId: '',
        // 获取个人文档- 弹框 的视图
        newViewerKey: 'fileSelector',
        showCheckbox: false,
        isEditing:'0',
        editingType:null,
        editingSuffix:['xlsx','xls','docx','doc','ppt','pptx','wps','pdf','rtf','csv'],
      };
    },
    inject: ['editRefreshLeftMenu'],
    props:{
      searchComponent:{
        type:Boolean,
        default:false
      }
    },
    computed: {
      viewer() {
        return this.$store.state.doc.viewer[this.viewerKey] 
      },
      newViewers() {
        return this.$store.state.doc.viewer[this.newViewerKey] // 弹框
      },
      checkedMap(){
        return this.$store.state.cart.views[this.cartViewKey].docIdMap
      },
      pageSize() {
        return 10;
        // return this.$store.state.doc.viewer[this.viewerKey].query.pageSize || 0
      },
      total() {
        return this.$store.state.doc.viewer[this.viewerKey].paging.total || 0
      },
      //购物车检查，判断是否选中
      cartChecker(){
        return (doc) => {
          const mapKey = doc.id
          return this.checkedMap[mapKey]
        }
      },

      coverImg(){
        return (doc) => doc.type == 1 ?(doc.coverImg!=null? this.$options.filters["thumbnailUrl"](doc.coverImg):morenImg ): defaultImg
      },
      // 文档类型过滤 -- 弹框
      filterFiles(){
          let that = this
          const newShowFiles = this.newViewers ? this.newViewers.showFiles : []

          newShowFiles.map( items =>{
            that.$set(items,'isChecked',false)
          })
          return this.options.type == null ? newShowFiles : newShowFiles.filter(f => f.type == this.options.type)
       
      },
      
    },
    methods: {
      handleRowClickEvent(row, column, event) {
         if(row.state == 1) return
         if(row.suffix == 'zip'){
              this.$message.error('zip格式不支持阅读！')
              return
          }
            // 非嵌入模式
            if(!this.$isRunInsert){
              // 指定格式用wps或者onlyoffice阅读
              if(this.editingSuffix.indexOf(row.suffix) !=-1 && this.isEditing == '1' ){
                  row.isStream = 1
              }else{
                  row.isStream = 2
              }
              if(row.isStream==1){
                if(this.editingType== '0'){
                      documentAPI.wpsOpenFile({
                          fileId:row.id
                      }).then(res=>{
                          if(res.code==0){
                            let title = '在线编辑'
                            if(row.suffix == 'pdf'){
                              title = 'PDF阅读'
                            }
                            if(Store.get("myPreference")){
                                  if(Store.get("myPreference").reader_model == '1'){
                                      const routeData = this.$router.resolve({
                                          path:'/wps',
                                          query:{
                                              urls:res.data
                                          }
                                      })
                                      window.open(routeData.href,'_blank');
                                  }else{
                                      this.$refs['createFile'].init(res.data,title)
                                  }
                              }else{
                                  this.$refs['createFile'].init(res.data,title)
                              }
                          } else {
                              this.$message.error(res.message)
                          }
                      })
                  }else{
                      documentAPI.getOnlyOfficeParameter(row.id).then(res=>{
                          if(res.code==0){
                              if(Store.get("myPreference")){
                                  if(Store.get("myPreference").reader_model == '1'){
                                      const routeData = this.$router.resolve({
                                          path:'/onlyOffice',
                                          query:res.data
                                      })
                                      window.open(routeData.href,'_blank');
                                  }else{
                                      this.$refs['onlyOffice'].init(res.data)
                                  }
                              }else{
                                  this.$refs['onlyOffice'].init(res.data)
                              }
                          } else {
                              this.$message.error(res.message)
                          }
                      })
                  }
              } else {
                this.handleCommand({type: 'click', row: row});
              }
            }
            else{
                // 嵌入方式
                const officeType = ['doc','docx','DOC','DOCX','xls','xlsx','XLS','XLSX','ppt','pptx','PPT','PPTX','pdf','PDF']
                const ofdType = ['ofd','OFD']
                if(officeType.indexOf(row.suffix)!=-1){
                    documentAPI.getOnlyOfficeParameter(row.id).then(res=>{
                        if(res.code==0){
                            const routeData = this.$router.resolve({
                                path:'/onlyOffice',
                                query:res.data
                            })
                        window.open(routeData.href,'_blank');   
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                } else if(ofdType.indexOf(row.suffix)!=-1){
                    window.open('/tools/reader/reader.html?id='+row.id,'_blank')
                } else {
                  if(row.type==0){
                        this.handleCommand({type: 'click', row: row});
                        return
                    }
                    this.$message.warning('暂不支持阅读,正在研发中,敬请期待...')
                }
            }
            // this.handleCommand({type: 'click', row: row});
        },
      showNewCrateFile(){
        this.$refs['changeFileType'].init()
      },
      showDialogEvent(){
        if(JSON.stringify(this.checkedMap) != '{}') {
          // let documentIdList = Object.keys(this.checkedMap)
          this.$ebus.$emit('showMaximize')
          this.$store.commit('setCartShow', true)
        } else {
          this.$message.error('请选择数据')
        }
      },
      // 分页
      pageChanged(num) {
        this.$emit('pageChange', num);
        // this.$store.dispatch('doc/loadChildren', {
        //   vkey:this.viewerKey,
        //   reset:true,
        //   pageNum: num,
        //   pageSize:20
        // })
        this.$ebus.$emit('callBackTab', 2)
      },

      //加载或刷新当前文件夹
      loadAndRefresh() {
        this.$store.dispatch("doc/loadChildren", {
          vkey: this.viewerKey,
          reset: true,
          pageSize:10
        });
      },
      
      //后退
      doFolderBack(){
        this.$store.dispatch("doc/backFolder", {
          vkey: this.viewerKey,
        })
        this.loadAndRefresh()

        if(this.viewer.depth == 0) {
            this.$store.commit('pageBackTextShow', '')
        }

        this.$store.commit('backFolerBread')
      },

      //文档集中处理器
      handleCommand({type, row}) {
        switch (type) {
          case "click":
            if (row.type == 0) {
              //打开文件夹
              this.handleEnterFolder(row);
            } else {
              if(row.documentId !=null){
                row.id =row.documentId
              }
              this.$ebus.$emit('publicReader',{
                  shareId:row.shareId, 
                  id: row.documentId || row.id,
                  documentId: row.documentId,
                  name:row.name,
                  suffix:row.suffix
              })
            }
            break;
          case "rename":
            this.renameId = row.id;
            this.$refs["folderName"].show({ mode: "rename",value:row.name});
            break;
          case "reload":
            this.$store.dispatch("document/loadChildren");
            break;
          //添加共享购物车
          case "addShare":
            this.$store.commit("cart/push", {
              viewerKey: this.cartViewKey,
              doc: row,
            })
            this.$nextTick(() => this.$forceUpdate())
            break
          //移除共享购物车
          case "removeShare":
            this.$store.commit("cart/remove", {
              viewerKey: this.cartViewKey,
              doc: row,
            })
            this.$nextTick(() => this.$forceUpdate())
            break
          case "upload":
            this.$ebus.$emit('clearSelect')
            this.$refs["uploadFile"].show();
            break;
          case "share":
            //多选文件处理
            const shareFiles = [row];

            if (this.$store.state.document.viewer.selection) {
              this.$store.state.document.viewer.selectionRows.map((item) => {
                if (item.id != row.id) {
                  shareFiles.push(item);
                }
              });
            }

            this.$refs["shareDialog"].show({
              mode: "new",
              value: shareFiles,
            });
            break;
          case "mkdir":
            this.$ebus.$emit('clearSelect')
            this.$refs["folderName"].show({ mode: "new", value: null });
            break;
          case "about":
            this.$refs["filePropsDialog"].show(row);
            break;
          case "download":
            this.$refs["fileDownloadDialog"].show(row);
            break;
          case "delete":
            this.loadingShow("删除中...")
            this.$store.dispatch('doc/checkDocumentHasShare',[row.id]).then((res)=>{
                    let {data:hasData,message:hasMessage,code:hasCode} = res
                    if(hasCode==0){
                        if(hasData.length>0){
                            this.$confirm('删除文件中存在共享文件, 是否继续删除?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                            }).then(() => {
                               this.removeFewMyFile(row)
                            }).catch(() => { 
                                this.loadingHide()    
                            });
                        } else {
                               this.removeFewMyFile(row)
                        }
                    } else {
                        this.$message.error(hasMessage)
                    }
                })
            break;
          case "selection":
            this.$store.commit("doc/setSelection", {
              value: true,
              item: row,
            });
            break;
          case "chain":
            this.$refs["fileChainDialog"].show('doc', row);
            break;
          case "printer":
            this.$refs["FilePrinter"].show(row);
            break;

          case "moveDocument": // 移动文档
            this.isMove = false;
            this.options.type = 0;
            this.docuInfoId = row;
            this.$refs["addDocumentDialog"].show(this.viewerKey);
            break;

          case "copysDocument": // 复制文档
            this.isMove = true;
            this.options.type = 0;
            this.docuInfoId = row;
            this.$refs["addDocumentDialog"].show(this.viewerKey);
            break;

          case "stars":
                this.addFavorite(row);
                break;
          case "quStart":
              this.quStarData(row);
          break;
          case "bindTag":
              this.bindTagEvent(row);
          break;
          case "bindTag":
              this.bindTagEvent(row);
          break;
          case 'sort':
            this.$refs.FileSort.show(row)
            break;
          case 'nosort':
             documentAPI.cancelMyDocumentFileSort(row.id).then(res => {
                if(res.code == 0){
                    this.$message.success('操作成功！');
                    this.loadAndRefresh()
                }else{
                    this.$message.error(res.message)
                }
            })
            break;

          case "tableshare":
            this.viewer.showFiles.forEach(items=>{
                this.$store.commit("cart/remove", {
                    viewerKey: this.cartViewKey,
                    doc: items,
                })
            })

            this.$store.commit("cart/push", {
                viewerKey: this.cartViewKey,
                doc: row,
            })
            let documentIdList = [row.id]
            this.$store.commit('setCartShow', true)

            this.$emit('nochecked', true)
          break;

          case "tranfromOFD": 
              this.loadingShow("转换中...")
                  this.$store.dispatch('doc/transformToOFD',row.id).then(res=>{
                     this.loadingHide()
                    if(res.code==0){
                        this.$message.success('转换成功')
                    } else {
                        this.$message.error('转换失败')
                    }
                  })
                break;
          case 'wpsEdit': 
            documentAPI.wpsOpenFile({
                      fileId:row.id
                  }).then(res=>{
                      if(res.code==0){
                          if(Store.get("myPreference")){
                              if(Store.get("myPreference").reader_model == '1'){
                                  const routeData = this.$router.resolve({
                                      path:'/wps',
                                      query:{
                                          urls:res.data
                                      }
                                  })
                                  window.open(routeData.href,'_blank');
                              }else{
                                  this.$refs['createFile'].init(res.data,'文件编辑')
                              }
                          }else{
                              this.$refs['createFile'].init(res.data,'文件编辑')
                          }
                      } else {
                          this.$message.error(res.message)
                      }
                  })
                  break;
          case 'onlyOffice': 
             documentAPI.getOnlyOfficeParameter(row.id).then(res=>{
                if(res.code==0){
                     if(Store.get("myPreference")){
                                if(Store.get("myPreference").reader_model == '1'){
                                    const routeData = this.$router.resolve({
                                        path:'/onlyOffice',
                                        query:res.data
                                    })
                                    window.open(routeData.href,'_blank');
                                }else{
                                    this.$refs['onlyOffice'].init(res.data)
                                }
                            }else{
                                this.$refs['onlyOffice'].init(res.data)
                            }
                } else {
                    this.$message.error(res.message)
                }
            })
          break;
        }
      },

      // 新建文件
      
      creatFile(){

      },

      //进入指定目录
      handleEnterFolder(folder) {
        // 文件夹的名称存起来
        this.$store.commit('folderBreadList', {doc: folder, tip: '我的文档'});

        if (folder) {
          if (folder.type == 0) {
            this.$store.dispatch("doc/enterFolder", {
              vkey: this.viewerKey,
              folder
            });
          }
        } else {
          this.$store.dispatch("doc/enterFolder");
        }

        if(this.viewer.depth > 0) {
            this.$store.commit('pageBackTextShow', '我的文档')
        }
      },

      createfilder() {
        this.$prompt("请输入文件名", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(({ value }) => {
            this.$message({
              type: "success",
              message: "你的邮箱是: " + value,
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入",
            });
          });
      },

      prev() {
        this.$store.dispatch("doc/doLoadPrevPage", {
          vkey: this.viewerKey,
        });
      },
      next() {
        this.$store.dispatch("doc/doLoadNextPage", {
          vkey: this.viewerKey,
        });
      },
      //列表项点击
      onItemClicked(key, docInfo, state) {
         this.showCheckbox = true

        switch (key) {
          case "click":
               //列表项点击
              if (docInfo.type == 1) {
                //文件单次点击勾选，再次点击取消
                const states = !this.docIdMap.hasOwnProperty(docInfo.id)
                this.onFileChecked(docInfo, states)
              
              } else {
                //进入文件夹
                this.$store.dispatch("doc/enterFolder", {
                  vkey: this.newViewerKey,
                  folder: docInfo,
                })
              }
            break;
          
          case "checked":
            this.onFileChecked(docInfo, state)
            break
        }
      },
      //勾选文件，单次点击勾选，再次点击取消
      onFileChecked(doc){
        this.addFile(doc) 
      },

      //勾选文件
      addFile(doc){
        const cacheKey = doc.id
        if (!this.docIdMap[cacheKey]) {
          this.docIdMap[cacheKey] = doc
          this.selectFiles.push(doc)
        }
      },

      //移除文件
      removeFile(data){
        let {item:doc,index} = data
            const cacheKey = doc.id
            
            //判断是否已添加
            if (this.docIdMap[cacheKey]) {
                if(this.selectFiles.length>1&&index!=0){
                    for (let itemIndex = 0, len = this.selectFiles.length; itemIndex < len; itemIndex++) {
                        if (this.selectFiles[itemIndex].id == cacheKey) {
                            this.selectFiles.splice(itemIndex)
                            this.$store.dispatch("doc/enterFolder", {
                                vkey: this.newViewerKey,
                                folder:this.selectFiles[this.selectFiles.length-1]
                            })
                            this.$store.state.doc.viewer[this.newViewerKey].depth = this.selectFiles.length
                            this.$store.state.doc.viewer[this.newViewerKey].paths.forEach((item,index)=>{
                                if(item.id==cacheKey){
                                    this.$store.state.doc.viewer[this.newViewerKey].paths.splice(index)
                                }
                            })
                            delete this.docIdMap[cacheKey]
                            break
                        }
                    }
                } else {
                  this.selectFiles.forEach(item=>{
                        delete this.docIdMap[item.id]
                    })
                    this.$store.state.doc.viewer[this.newViewerKey].current = 0;
                      this.$store.dispatch("doc/loadChildren", {
                        vkey: this.newViewerKey,
                        reset: true,
                        pageSize:10
                    })
                    this.selectFiles = []
                    this.$store.state.doc.viewer[this.newViewerKey].depth = 0
                    this.$store.state.doc.viewer[this.newViewerKey].paths = [];
                }
            }
      },
      //文件目录返回
      doFolderBacks() {
        let lastCacheKey = this.selectFiles[this.selectFiles.length-1] ? this.selectFiles[this.selectFiles.length-1].id : 0
            if (this.docIdMap[lastCacheKey]) {
                this.selectFiles.splice(this.selectFiles.length-1)
                delete this.docIdMap[lastCacheKey]
            }
        this.$store.dispatch("doc/backFolder", {
          vkey: this.newViewerKey
        })
      },
      /**
       * 页面加载
       * options: {
       *    count: 选择文件数，默认不限制
       *    type: null, 选择类型，默认包含文件夹和文件， 0=文件夹
       *    callback: 回调函数，默认为 onFileSelected
       * }
       */ 
      onLoad(){
        //数据类型处理
        this.options.count = 1
        if(this.options.count) this.options.count = Number(this.options.count)

          this.options = Object.assign(this.options, this.options)
          
          this.$store.commit("doc/createViewer", {
            viewerKey: this.newViewerKey
        })
        this.$store.dispatch("doc/loadChildren", {
          vkey: this.newViewerKey,
          reset: true,
          fileType:'0'
        })
      },
       // 清空事件
      closeEvent(){
        this.onLoad()

        this.selectFiles.forEach(a=>{
          this.$set(a,'isChecked',false)
          this.docIdMap[a.id].isChecked = false
        })
        this.docIdMap = {}
        this.selectFiles = []
        this.showCheckbox = false    

      },

      // 添加收藏
      addFavorite(docInfo) {
          this.$store.dispatch("collection/addDollection", docInfo.id)
              .then((data) => {
                  this.$message.success("收藏成功");
                  this.loadAndRefresh();
              }).catch((err) => {
                  this.$msg.error("收藏发生错请稍后再试");
              });
      },
      // 取消收藏
      quStarData(item) {
          dollectionAPI.removeRecoredId(item.id).then(res=>{
              if(res.code == 0) {
                  this.$message.success("取消收藏成功");
                  this.loadAndRefresh();
              } else {
                  this.$message.error(res.message); 
              }
          }).catch(error=>{
              this.$message.error(error);
          })
      },
      bindTagEvent(row) {
          this.$refs.bindTagPage.disableHidden = true;
          this.$nextTick(()=>{
              this.$refs.bindTagPage.init(row);
          })
      },
      // 多删除
      handleMyDocRemoveClickEvent(){
          if(JSON.stringify(this.checkedMap) != '{}'){
            let ids = []
            this.viewer.showFiles.forEach(fileItems =>{
              if(this.checkedMap[fileItems.id] != undefined) {
                ids.push(this.checkedMap[fileItems.id].id)
              }
            })
            
            this.$store.dispatch('doc/checkDocumentHasShare',ids).then((res)=>{
                    let {data:hasData,message:hasMessage,code:hasCode} = res
                    if(hasCode==0){
                        if(hasData.length>0){
                            this.$confirm('删除文件中存在共享文件, 是否继续删除?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                            }).then(() => {
                              this.viewer.showFiles.forEach(fileItems =>{
                                if(this.checkedMap[fileItems.id] != undefined) {
                                  this.$store.commit("cart/remove", {
                                      viewerKey: this.cartViewKey,
                                      doc: fileItems,
                                  })
                                }
                              })
                               this.removeMoreMyFile(ids)
                            }).catch(() => {         
                            });
                        } else {
                          this.viewer.showFiles.forEach(fileItems =>{
                              if(this.checkedMap[fileItems.id] != undefined) {
                                this.$store.commit("cart/remove", {
                                    viewerKey: this.cartViewKey,
                                    doc: fileItems,
                                })
                              }
                            })
                               this.removeMoreMyFile(ids)
                        }
                    } else {
                        this.$message.error(hasMessage)
                    }
                })

          } else {
              this.$message.error('请选择数据')
          }
      },
      removeMoreMyFile(ids){
            this.$store.dispatch("doc/checkedRemove", {
                vkey: this.viewerKey,
                id: ids
            }).then((data) => {
                // 文件夹类型的时候 删除刷新左侧菜单
                if(data) {
                    this.$message.success('删除成功')
                    this.editRefreshLeftMenu();
                    this.loadAndRefresh()
                    this.$store.dispatch('login/getUserUsedDist')
                } else {
                    this.$message.error('删除失败')
                    // this.$message.error("当前文件中有未关闭的共享，请先关闭共享")
                }
            }).catch(err => this.$message.error(err))
        },
        removeFewMyFile(row){
            this.$store.dispatch("doc/checkedRemove", {
                vkey: this.viewerKey,
                id: [row.id]
            }).then((data) => {
                this.loadingHide()
                // 文件夹类型的时候 删除刷新左侧菜单
                if(data){
                    this.$message.success('删除成功')
                     this.$store.dispatch('login/getUserUsedDist')
                    if(row.type == 0) {
                        this.editRefreshLeftMenu();
                    }
                    this.$store.commit("cart/remove", {
                        viewerKey: this.cartViewKey,
                        doc: row,
                    })
                    this.loadAndRefresh()
                    this.$nextTick(() => this.$forceUpdate())
                } else {
                    this.$message.error('删除失败')
                }
            }).catch(err => this.$message.error(err))
        },
        // 是否开启了在线编辑功能
        getValueByKey(){
            msgAPI.getValueByKey('system.edit.enable').then(res => {
                if(res.code == 0){
                    this.isEditing  = res.data.configValue;
                    // 如果已经开启了在线编辑功能，那么检测是用哪种方式
                    if(this.isEditing == '1'){
                        msgAPI.getValueByKey('system.edit.type').then(res => {
                            this.editingType = res.data.configValue;
                        })
                    }
                }
            })
        }

    },
    created() {
      this.onLoad();
      this.getValueByKey()

      this.$store.state.doc.viewer['my'].current = null;
      this.$store.dispatch("doc/loadChildren", {
          vkey: this.viewerKey,
          reset: true,
          pageSize:10
      });
      this.doFolderBack()

      this.$ebus.$on('cart:remove', (file) => {
        this.$nextTick(() => this.$forceUpdate())
      })    
    },
    mounted() {
      this.$ebus.$on('clear',res=>{
        this.$nextTick(() => this.$forceUpdate())
      })

      this.$ebus.$on('myDocGoBackBtn', text=>{
          this.doFolderBack()
      })
      // 跳转到
      this.$ebus.$on('enterMyDocData', vals=>{
          if(vals == '我的文档') {
              this.$store.state.doc.viewer[this.viewerKey].current = 0;
              this.loadAndRefresh()
              this.viewer.depth = 0;
              this.$store.state.doc.viewer[this.viewerKey].depth = 0;
              this.$store.state.doc.viewer[this.viewerKey].paths = [];
              this.$store.commit('pageBackTextShow', '')
              this.$store.commit('clearFolderBreadList')
          } else {
              if (vals.data.type == 0) {
                  this.$store.dispatch("doc/enterFolder", {
                      vkey: this.viewerKey,
                      folder: vals.data
                  });
              }
              if(vals.data.children.length > 0) {
                  this.$store.commit('folderBreadDeleteNum', {id: vals.path, vkey: 'my'})
              }
          }
      })
      // 共享
      this.$ebus.$on('handleMyShareEvent', val=>{
          this.showDialogEvent()
          
      })

      this.$ebus.$on('handleMyDocRemoveClickEvent', val=>{
          this.handleMyDocRemoveClickEvent();
      })
    },
    beforeDestroy() {
        this.$ebus.$off('myDocGoBackBtn');
        this.$ebus.$off('enterMyDocData');
        this.$ebus.$off('handleMyShareEvent');
        this.$ebus.$off('handleMyDocRemoveClickEvent');
    }
  };
</script>
<style lang='less' scoped>
  @import "../../styles/index";

  .tools-box {
    /* position: relative; */
    text-align: right;

    .tools-panel{
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
      color: #666666;
      cursor: pointer;
    }
  }
  .el-row {
    height:calc(100vh - 265px);
    overflow: auto;
    .el-col-5 {
      width:19.83%;
    }
  }

  .el-main-width {
    padding-top: 0px;
  }

  .addbox {
    position: relative;
  }

  .addbox i {
    position: absolute;
    right: 0;
    bottom: -31px;
    font-size: 20px;
    z-index: 55;
  }
   .warm-row{
        background: #ccc!important;
        opacity: 0.6;
        .uploading{
            position: absolute;
            right: 0;
            top:0;
            font-size: 14px;
            background: #E6A23C;
            display: inline-block;
            border-top-left-radius: 15px;
            border-bottom-right-radius: 15px;
            color: #fff;
            padding: 5px 10px;
            z-index:1;
        }
        .document-bottom{
            .el-dropdown{
                pointer-events: none;
                cursor: not-allowed;
            }
            .el-button{
                pointer-events: none;
                cursor: not-allowed;
            }
        }
        
    }

  .document {
    // height: 400px;
    // background: red;
    text-align: center;
    // border: 1px solid @Brand;
    margin-bottom: 20px;
    cursor: pointer;
    user-select: none;
  }

  .document-top {
    position: relative;
    padding: 10px;
    height: 200px;
    overflow: hidden;
  }

  .document-top img {
    width: 100%;
    height: 100%;
  }

  .document-middle {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: @h6;
    height: 97px;
    overflow: hidden;
    padding: 10px 20px;
  }

  .document-bottom {
    // padding-bottom: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .btn{
      font-size: 16px;
    }
    .checkbox-span {
      width: 13px;
      height: 13px;
      border-radius: 3px;
      border: 1px solid #409EFF;
    }
    .checkbox-span1 {
      width: 13px;
      height: 13px;
      border-radius: 3px;
      border: 1px solid #409eFF;
      background: #409EFF;
      color: #fff;
      position: relative;
      i {
        font-size: 12px;
        position: absolute;
        top: 1px;
        right: 1px;
      }
    }

  }

  .title {
    text-align: left;
    font-size: @h4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin: 10px 0;
  }

  .info-tag {
    margin-top: 5px;
  }

  .info-tag i {
    margin-right: 10px;
  }

  .red_share {
    margin-top: 10px;
    color: @Info;
  }

  .more {
    top: 10px;
    right: 10px;
    position: absolute;
  }

  .more-menu {
    font-size: 18px;
  }
  .pagebox {
    margin-top: 20px;
    text-align: left;
  }
  .no-data-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    margin-right: 10%;
    img {
        width: 380px;
        height: 280px;
    }
    span {
        color: #bdc1cd;
        font-size: 16px;
        margin-top: 15px;
        font-weight: 100px;
    }
}
</style>